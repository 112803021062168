<template>
    <div class="wrap">
        <div class="forget-box" v-if="getSMS">
            <div class="forget-title">找回密码</div>
            <div class="forget-item">
                <span>手机号：</span>
                <el-input type="text" v-model="phone" maxlength="11" placeholder="请输入手机号"></el-input>
            </div>
            <div class="forget-item">
                <span>短信验证码：</span>
                <el-input maxlength="4" placeholder="请输入验证码" v-model="imageCode"></el-input>
                <img class="imageCode" :src="imgCodeSrc" width=80 height=20  @click="refreshImageCode" >
            </div>
            <div class="forget-item">
                <span>短信验证码：</span>
                <el-input v-model="smsCode" placeholder="请输入短信验证码"></el-input>
                <el-button class="getCode" @click="getCode" size="medium" :disabled="disabled" type="primary">{{getTxt}}</el-button>
            </div>
            <div class="forget-btn">
                <el-button type="primary" @click="backLogin">取消</el-button>
                <el-button type="primary" @click="checkSMS">下一步</el-button>
            </div>
        </div>
        <div class="forget-box" v-else>
            <div class="forget-title">设置新密码</div>
            <div class="forget-item">
                <span>新密码：</span>
                <el-input type="text" v-model="newPassword" placeholder="请输入新密码"></el-input>
            </div>
            <p class="password-tips">新密码应不少于 8 位，包括大写字母、小写字母和数字。</p>
            <div class="forget-item">
                <span>请再次输入：</span>
                <el-input type="text" v-model="secondPassword" placeholder="请再次输入密码"></el-input>
            </div>
            <div class="forget-btn">
                <el-button type="primary" @click="submitUpdate">完成</el-button>
            </div>
        </div>
        
    </div>
</template>
<script>
import {guid} from '@/utils/tools';
import {CommonApi} from '@/api';
import Md5 from 'js-md5';
export default {
    data() {
        return {
            imageCode: '',
            smsCode: '',
            imageGuid: '',
            getTxt:'获取短信验证码',
            time:60,
            disabled: false,
            resetBtn: false,
            phone:'',
            getSMS: true,
            newPassword:'',
            secondPassword:''
        }
    },
    mounted() {
        this.refreshImageCode();
    },
    computed: {
        imgCodeSrc: function () {
            return CommonApi.getImageCode(this.imageGuid);
        }
    },
    methods: {
        refreshImageCode: function () {
            this.imageGuid = guid();
            console.log("imageGuid = " + this.imageGuid);
        },
        async getCode(){
            if (!this.phone) {
                this.$message.warning("请输入手机号码");
                return;
            }
            if (!this.imageCode) {
                this.$message.warning(this.translate('FROM.PLACEHOLDER', {param: 'LOGIN.IMAGE_VERIFICATION_CODE'}));
                return;
            }
            this.timer();
            let result = await CommonApi.sendVerifyCodeSms({
                smsType: 'FORGOT_PASSWORD',
                phone: this.phone,
                imageCode: this.imageCode,
                imageCodeId: this.imageGuid
            })
            if(result.success){
                this.$message.success('发送成功');
                this.resetBtn = false
            } else{
                this.$message.error(result.message);
                this.resetBtn = true
            }
        },
        timer() {
            if (this.resetBtn) {
                clearTimeout(this.timer)
                this.time = 60;
                this.getTxt = "获取短信验证码";
                this.disabled = false;
            } else {
                if (this.time > 0) {
                    this.time--;
                    this.getTxt = this.time +'S后重新获取';
                    this.disabled = true;
                    setTimeout(this.timer, 1000);
                } else {
                    this.time = 60;
                    this.getTxt = "获取短信验证码";
                    this.disabled = false;
                }
            }
        },
        backLogin(){
            this.$router.go(-1)
        },
        async checkSMS(){
            if (!this.phone) {
                this.$message.warning("请输入手机号码");
                return;
            }
            if (!this.imageCode) {
                this.$message.warning(this.translate('FROM.PLACEHOLDER', {param: 'LOGIN.IMAGE_VERIFICATION_CODE'}));
                return;
            }
            if (!this.smsCode) {
                this.$message.warning('请输入短信验证码');
                return;
            }
            let params = {
                smsType: 'FORGOT_PASSWORD',
                phone: this.phone,
                smsCode: this.smsCode,
                imageCode: this.imageCode,
                imageCodeId: this.imageGuid
            }
            let result = await CommonApi.verifySmsCode(params)
            if(result.success){
                this.getSMS = false
                this.token = result.data
            } else{
                this.refreshImageCode();
            }
            
        },
        async submitUpdate(){
            if (!this.newPassword) {
                this.$message.warning("请输入密码");
                return;
            }
            if (this.newPassword!=this.secondPassword) {
                this.$message.warning("请输入一致的密码");
                return;
            }
            let params = {
                newPassword: Md5(this.newPassword),
                phone: this.phone,
                smsCode: this.token
            }
            let result = await CommonApi.forgetPassword(params)
            if(result.success){
                this.$message.success("修改成功");
                this.$router.go(-1)
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .forget-box{
        height: 368px;
        width: 440px;
        position: absolute;
        background-color: #FFFFFF;
        top: 150px;
        right: 15%;
        padding: 20px;
    }
    .forget-title{
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    .forget-item{
        display: flex;
        padding: 10px 0;
        line-height: 40px;
        span{
            width: 90px;
            font-size: 13px;
            text-align: right;
        }
        .el-input{
            flex: 1;
        }
        .el-button{
            margin-left: 10px;
        }
        img{
            height: 40px;
            width: 100px;
        }
    }
    .forget-btn{
        display: flex;
        padding: 20px 0;
        .el-button{
            flex: 1;
        }
    }
    .password-tips{
        margin: -5px auto 0;
        font-size: 11px;
        padding-left: 100px;
        color: #666;
    }
</style>