<template lang="pug">
    div.about
        h1 This is an about page
        div url = {{url}}
        div {{all}}
        div
</template>

<script>
    // @ is an alias to /src
    import HelloWorld from '@/components/HelloWorld.vue'

    export default {
        name: 'home',
        components: {
            HelloWorld
        },
        data: function () {
            return{
                url: process.env.VUE_APP_BASE_API,
                all: process.env

            }
        }
    }
</script>